import React from 'react'


const Footer = () => {
  return (
    <>
      <footer>
       <h6>Developed By : <a href='https://www.linkedin.com/in/pratapbabu/' target='_blank'> Tej Pratap</a></h6>
      </footer>
    </>
  )
}

export default Footer