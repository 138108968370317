//Backend URL:

//local
// export const BASE_URL = "http://127.0.0.1:8000";

//Stagging
//export const BASE_URL = "http://142.44.247.133:8000";

//Live
export const BASE_URL = "https://api.tejtech.in.net";
// export const BASE_URL = "https://tejtech.com";
